export const SEVERAL_MATCHED = 'SEVERAL_MATCHED'
export const NONE_MATCHED = 'NONE_MATCHED'

export const VK_LINK_REGEX = /^vk.com\/(id\d+|[A-Za-z0-9_.]+)$/

export const ADMIN_ROLE = 'ADMIN'
export const VIEWER_ROLE = 'VIEWER'

export const LIMITER = 'LIMITER'
export const GIVE_ACCESS = 'GIVE_ACCESS'

export const SELECT_OPTIONS = {
  language: {
    noResults: () => 'Совпадений не найдено',
  },
}

export const DISABLE_RESERVE_FORMAT_ID = 5

export const FORT_BOYARD_PREFIX = 'Форт Боярд /'
export const RYZHAYA_PREFIX = 'Рыжая /'

export const CHANGE_SOURCES = {
  USER: 'USER',
  PROGRAM: 'PROGRAM',
}
export const MIN_VK_CABINET_LIMIT = 100

export const API_VERSION = '5.124'
