import './swal-with-select2.css'
import swal from 'sweetalert'
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'
import { SELECT_OPTIONS } from '../common/constants'
import './swal-with-cabinet-select-and-number-input.css'
import { parseMoney } from '../common/utils'

export class SwalUtils {
  static async showSelectCabinetAndNumberSwal(sourceLine, cabinets) {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = `
  Я не смог уверенно понять вводную строку
  <br/>"${sourceLine}"<br/>
  Выбери, пожалуйста, кабинет, и впиши на сколько нужно увеличить лимит<br/>
  <label for="cabinet-select" class="cool-swal-label">
    Кабинет:
    <select id="cabinet-select"></select>
  </label>
  <label for="number-input" class="cool-swal-label">
    На сколько увеличить лимит:
    <input type="text" class="swal-number-input" id="number-input">
  </label>
  Если это окно показывается слишком часто - напиши разработчику
  `
    const promise = swal({
      content: wrapper,
      buttons: {
        confirm: {
          value: true,
          text: 'Выбрал',
        },
        abort: {
          text: 'Отмена',
          value: 'ABORT',
          className: 'swal-button-abort',
        },
        skip: {
          text: 'Пропустить',
          value: 'SKIP',
          className: 'swal-button-warning',
        },
      },
      closeOnEsc: false,
      closeOnClickOutside: false,
    })
    const select = $('#cabinet-select')
    const options = cabinets.map(({ id, name }) => ({ id, text: name }))
    select.select2({
      ...SELECT_OPTIONS,
      placeholder: 'Выбрать',
      data: options,
    })
    let changeLimitNumber = ''
    document.querySelector('#number-input').addEventListener('change', (e) => {
      changeLimitNumber = e.target.value
    })
    let selectedCabinet = cabinets[0].id
    select.on('select2:select', (e) => {
      selectedCabinet = +e.target.value
    })
    const response = await promise
    if (response === 'SKIP') return { skip: true }
    if (response === 'ABORT') return { abort: true }
    changeLimitNumber = parseMoney(changeLimitNumber)
    return {
      number: changeLimitNumber,
      cabinetId: selectedCabinet,
    }
  }
}
