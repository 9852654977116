export class HistoryService {
  static setBackHandler(handler) {
    HistoryService.backHandler = handler
    window.onpopstate = handler
  }

  static back() {
    HistoryService.backHandler()
  }

  static pushState() {
    window.history.pushState({}, '')
  }
}

/** @private */
HistoryService.backHandler = () => {}
