// eslint-disable-next-line import/no-cycle
import { getClientCabinets } from '../common/vk-utils'
import $ from 'jquery'
import { SELECT_OPTIONS } from '../common/constants'
import { showLoader } from '../common/loader'
import swal from 'sweetalert'
// eslint-disable-next-line import/no-cycle
import { getNumbers } from '../common/string-utils'
// eslint-disable-next-line import/no-cycle
import { VkStorageService } from '../common/vk-storage-service'

let cabinetNamePrefix = ''
if (process.env.NODE_ENV === 'development') cabinetNamePrefix = 'dev_'

// Do NOT change order of the cabinets,
// because they are saved in VK with their indexes
// If you swap order of cabinets - they swap values on prod
// Always add new to the end
// In saved data in VK we start index with 1, not with 0
const cabinetNames = [
  'Форт Боярд',
  'Emitsu',
  'Рыжая / резерв',
  'Рыжая / оффшор',
]

export const createCabinetIdentifier = (index) =>
  `${cabinetNamePrefix}reserveCabinet${index}`

const cabinetIndexes = cabinetNames.map((name, index) => index + 1)
const cabinetIdentifiers = cabinetIndexes.map((index) =>
  createCabinetIdentifier(index)
)
async function initCabinetSelect(selectId, selectedCabinet, allCabinets) {
  let cabinetsToSuggest = [...allCabinets]
  if (!selectedCabinet) cabinetsToSuggest = [...cabinetsToSuggest]
  const options = cabinetsToSuggest.map(({ id, name }) => ({ id, text: name }))
  const select = $(`#${selectId}`)
  select.prepend('<option></option>')
  select.select2({
    ...SELECT_OPTIONS,
    data: options,
    placeholder: 'Выбери кабинет',
    allowEmpty: true,
  })
  if (selectedCabinet) {
    select.val(selectedCabinet)
    select.trigger('change')
  }
}

function getElementValueById(cabinetId) {
  return document.getElementById(cabinetId).value
}

export async function setReserveCabinets(cabinets) {
  const promises = cabinets.map(({ index, value }) =>
    VkStorageService.set(createCabinetIdentifier(index), value)
  )
  const responses = await Promise.all(promises)
  if (!responses.every((item) => item === 1)) {
    const unsavedCabinets = responses
      .map((item, index) => {
        if (item === 1) return false
        return `Кабинет ${index + 1} не был сохранен`
      })
      .filter(Boolean)
      .join(', ')
    throw new Error(`${unsavedCabinets}\nПопробуй еще раз`)
  }
  await swal({ text: 'Успешно сохранено', icon: 'success' })
}

async function save() {
  const cabinets = []
  for (let i = 1; i <= cabinetNames.length; i += 1) {
    const value = getElementValueById(createCabinetIdentifier(i))
    cabinets.push({ value, index: i })
  }
  const hideLoader = showLoader({
    elementIdsToDisable: cabinetIdentifiers,
    buttonId: 'save-button',
  })
  try {
    await setReserveCabinets(cabinets)
  } finally {
    hideLoader()
  }
}

function convertCabinetName(identifier) {
  const cabinetId = +getNumbers(identifier)
  const cabinetIndex = cabinetId - 1
  return cabinetNames[cabinetIndex]
}

function createCabinetHtml(cabinet) {
  return `
    <div class="form-group">
      <label for="${cabinet.name}">
        ${convertCabinetName(cabinet.name)}
      </label>
      <select id="${cabinet.name}" class="form-control"></select>
    </div>
  `
}

function renderCabinet(reserveCabinet, allCabinets) {
  const div = document.createElement('div')
  div.innerHTML = createCabinetHtml(reserveCabinet)
  const app = document.querySelector('#app')
  app.append(div)
  initCabinetSelect(reserveCabinet.name, reserveCabinet.id, allCabinets)
}

function renderReserveCabinets(reserveCabinets, allCabinets) {
  const app = document.querySelector('#app')
  app.innerHTML = ''
  reserveCabinets.map((reserveCabinet) =>
    renderCabinet(reserveCabinet, allCabinets)
  )
}

function renderSaveButton() {
  const button = document.createElement('button')
  button.setAttribute('id', 'save-button')
  button.classList.add('main-button')
  button.addEventListener('click', save)
  button.innerText = 'Сохранить'
  document.querySelector('.app').append(button)
}

export async function getReserveCabinetsByIndexes(indexes) {
  const keys = indexes.map((index) => createCabinetIdentifier(index))
  const response = await VkStorageService.getByKeys(keys)
  return response.map(({ key, value }) => ({ name: key, id: +value }))
}

async function loadReserveCabinets() {
  return getReserveCabinetsByIndexes(cabinetIndexes)
}

export async function initReserveCabinetsPage() {
  const [reserveCabinets, allCabinets] = await Promise.all([
    loadReserveCabinets(),
    getClientCabinets(),
  ])
  renderReserveCabinets(reserveCabinets, allCabinets)
  renderSaveButton()
}
