/* eslint-disable no-param-reassign */
import catta from 'catta'
import { handleChunkedMethod } from './utils'
import './vk-link.css'
import swal from 'sweetalert'
// eslint-disable-next-line import/no-cycle
import { tryToSetCabinetId } from './user-utils'
import { showErrorAlert } from './swal-utils'
import { API_VERSION } from './constants'

let requestTime = 0
const apiUrl = 'https://api.vk.com/method/'

class NiceError extends Error {
  constructor(message, haveShownError) {
    super(message)
    this.haveShownError = haveShownError
  }
}

function getNiceErrorText(errorCode, errorMessage) {
  const errors = {
    1: 'Неизвестная для ВК ошибка. Попробуй позже',
    5: 'Авторизация не удалась, обнови токен доступа к ВК\nКнопка в меню сверху',
    6: 'Слишком много запросов в секунду',
    7: 'Нет прав для выполнения данного действия',
    9: 'Слишком много однотипных действий',
    10: 'Внутренняя ошибка ВК. Попробуй позже',
    14: 'Требуется ввод капчи, но ее обработка не сделана',
    15: 'Доступ к контенту запрещен',
    17: 'Требуется валидация пользователя',
    29: 'Достигнут количественный лимит ВК',
    103: 'Превышено ограничение на количество переменных',
    113: 'Неверная ссылка на пользователя ВК',
    600: 'Нет прав на выполнения этого действия с РК',
    601: 'Превышено количество запросов за день.\nПопробуй позже',
    602: 'Часть данных не была сохранена',
    603: 'Произошла ошибка при работе с РК',
  }
  const niceErrorText = errors[errorCode] || 'Неизвестная ошибка'
  return `${niceErrorText}\n${errorMessage}`
}

function vk(options) {
  const {
    method,
    data = {},
    hardRetryIfNetworkError = false,
    retryModalText,
  } = options
  data.access_token = data.access_token || localStorage.getItem('accessToken')
  data.v = data.v || API_VERSION
  return new Promise((resolve, reject) => {
    const now = Date.now()
    function request() {
      requestTime = now
      catta({
        type: 'jsonp',
        timeout: 10,
        url: apiUrl + method,
        data,
      }).then(
        (res) => {
          if (res.response) {
            resolve(res.response)
          } else {
            const { error_code: errorCode } = res.error
            const errorMessage = res.error.error_msg
            const errorNiceText = getNiceErrorText(errorCode, errorMessage)
            showErrorAlert({
              title: 'Возникла ошибка при работе с ВК',
              text: errorNiceText,
            })
            // eslint-disable-next-line no-console
            console.error(res.error)
            reject(new NiceError(`#${errorCode}: ${errorMessage}`, true))
          }
        },
        (err) => {
          if (hardRetryIfNetworkError) {
            swal({
              icon: 'error',
              title: 'Сетевая ошибка',
              text: retryModalText,
              button: 'Попробовать еще раз',
              closeOnEsc: false,
              closeOnClickOutside: false,
            }).then(() => {
              vk(options).then(resolve, reject)
            })
          } else {
            showErrorAlert({
              text:
                'Сетевая ошибка.\n' +
                'Проверь соединие с Интернетом и попробуй еще раз',
            })
            // eslint-disable-next-line no-console
            console.error(err)
            reject(new NiceError(err.message || err.name, true))
          }
        }
      )
    }
    const difference = now - requestTime
    if (requestTime && difference < 500) setTimeout(request, difference)
    else request()
  })
}

async function getClientCabinets() {
  let parentCabinetId = localStorage.getItem('cabinetId')
  if (!parentCabinetId) parentCabinetId = await tryToSetCabinetId()
  const clientCabinets = await vk({
    method: 'ads.getClients',
    data: {
      account_id: parentCabinetId,
    },
  })
  return clientCabinets.map((cabinet) => ({
    id: cabinet.id,
    name: cabinet.name,
    limit: +cabinet.all_limit,
  }))
}

async function updateLimits(modifications) {
  const parentCabinet = localStorage.getItem('cabinetId')
  const modificationsToWork = modifications.filter(({ skip }) => !skip)
  const results = await handleChunkedMethod({
    objects: modificationsToWork,
    requestFn: (chunk) => {
      const requestClientsData = chunk.map((modification) => ({
        client_id: modification.cabinetId,
        all_limit: modification.newLimit,
      }))
      return vk({
        method: 'ads.updateClients',
        data: {
          account_id: parentCabinet,
          data: JSON.stringify(requestClientsData),
        },
        hardRetryIfNetworkError: true,
        retryModalText:
          'Возможно лимиты не были установлены.\n' +
          'Проверь соединение с интернетом и нажми кнопку "Попробовать еще раз"',
      })
    },
  })
  modifications.forEach((modification) => {
    if (!modification.skip) {
      const resultObj = results.find(
        (cabinet) => cabinet.id === modification.cabinetId
      )
      if (!resultObj)
        throw new Error(
          `bad response from VK. cabinet "${
            modification.cabinetId
          }" was not found`
        )
      if (resultObj.error_code || resultObj.error_desc) {
        modification.errorText = getNiceErrorText(
          resultObj.error_code,
          resultObj.error_desc
        )
        modification.success = false
      } else {
        modification.success = true
      }
    }
  })
  return modifications
}

let secretUserKey

async function getSecretUserKey() {
  if (!secretUserKey) {
    const response = await vk({
      method: 'users.get',
    })
    secretUserKey = String(response[0].id)
  }
  return secretUserKey
}

export {
  vk,
  getClientCabinets,
  updateLimits,
  getSecretUserKey,
  NiceError,
  getNiceErrorText,
}
