import { decrypt } from './decrypt-service'
import { formatMoney } from './utils'
import { createLinkToCabinet } from './string-utils'

const ICONS = {
  ERROR: '❌',
  SUCCESS: '✅',
  SKIP: '⚠️',
}

async function sendTgMessage({ text, chatId, botToken }) {
  const baseApiUrl = `https://api.telegram.org/bot${botToken}`
  const sendMessageUrl = `${baseApiUrl}/sendMessage`
  fetch(sendMessageUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      chat_id: chatId,
      text,
      parse_mode: 'Markdown',
      disable_web_page_preview: true,
    }),
  })
}

function createMessageRow(cabinet) {
  const {
    success,
    cabinetId,
    cabinetName,
    previousLimit,
    newLimit,
    errorText,
    skip,
    sourceLine,
  } = cabinet
  if (skip) {
    return `${ICONS.SKIP} *${sourceLine}* пропущен`
  }
  let icon
  if (success === true) icon = ICONS.SUCCESS
  else if (success === false) icon = ICONS.ERROR
  else
    throw new Error(
      `Invalid cabinet to create message: ${JSON.stringify(cabinet)}`
    )
  const link = createLinkToCabinet(cabinetId)
  const linkMarkdown = `[${cabinetName}](${link})`
  const error = errorText ? `\nОшибка: ${errorText.replace('\n', ' ')}` : ''
  const formattedPrevLimit = formatMoney(previousLimit)
  const formattedNewLimit = formatMoney(newLimit)
  return `${icon} *${sourceLine}* > ${linkMarkdown}:\n${formattedPrevLimit}₽ –> ${formattedNewLimit}₽${error}`
}

function createMessage(cabinets, loanNotification) {
  const rows = cabinets.map((cabinet) => createMessageRow(cabinet)).join('\n\n')
  return `Сменил лимиты:\n\n${rows}\n\n${loanNotification}`
}

async function sendLog(cabinets, loanNotification) {
  const text = createMessage(cabinets, loanNotification)
  let chatId
  if (process.env.NODE_ENV === 'production') {
    const encryptedProdChatId = process.env.ENCRYPTED_PROD_CHAT_ID
    chatId = await decrypt(encryptedProdChatId)
  } else chatId = process.env.DEV_TELEGRAM_CHAT_ID
  let botToken
  if (process.env.NODE_ENV === 'production') {
    const encryptedBotToken = process.env.ENCRYPTED_PROD_TELEGRAM_BOT_TOKEN
    botToken = await decrypt(encryptedBotToken)
  } else botToken = process.env.DEV_TELEGRAM_BOT_TOKEN
  await sendTgMessage({ text, chatId, botToken })
}

export { createMessage, createMessageRow, sendLog, ICONS }
