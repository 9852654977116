import 'hamburgers/dist/hamburgers.min.css'
import './menu.css'
import { authenticate, tryToSetCabinetId } from './user-utils'

const { body } = document

function insertMenu() {
  const menuHtml = `
  <div class="mobile-menu-handler">
    <button class="hamburger hamburger--squeeze" type="button">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
    </button>
  </div>
  <nav class="menu">
    <ul>
      <li><a href="/">Изменить лимиты</a></li>
      <li><a href="/give-access.html">Выдать доступ</a></li>
      <li id="refresh-token">Обновить токен доступа</li>
      <li id="change-cabinet">Изменить кабинет агентства</li>
      <li><a href="/change-reserve.html">Изменить резервные кабинеты</a></li>
    </ul>
  </nav>
  `
  body.innerHTML = menuHtml + body.innerHTML
}

function addMenu() {
  insertMenu()
  const hamburger = document.querySelector('.hamburger')

  function toggleMenu() {
    hamburger.classList.toggle('is-active')
    body.classList.toggle('menu-open')
  }

  hamburger.addEventListener('click', () => {
    toggleMenu()
  })

  function addMenuHandler(selector, handler) {
    document.querySelector(selector).addEventListener('click', () => {
      handler()
      toggleMenu()
    })
  }

  addMenuHandler('#refresh-token', () => {
    authenticate('Обновить токен доступа ВК', 'Авторизоваться ВК', false, true)
  })

  addMenuHandler('#change-cabinet', () => {
    tryToSetCabinetId(true)
  })
}

export { addMenu }
