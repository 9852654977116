import Fuse from 'fuse.js'
import { NONE_MATCHED, SEVERAL_MATCHED } from '../common/constants'
import swal from 'sweetalert'
import { SwalUtils } from './swal-utils'
import { getDuplicatedItems } from '../common/utils'

function getFuzzyMatchingCabinets(name, cabinets) {
  const options = {
    caseSensitive: true,
    shouldSort: true,
    threshold: 0.6,
    tokenize: true,
    location: 0,
    distance: 50,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name'],
  }
  let fuse = new Fuse(cabinets, options)
  let response
  try {
    response = fuse.search(name)
  } catch (e) {
    // pattern is too long
    fuse = new Fuse(cabinets, { ...options, maxPatternLength: name.length })
    response = fuse.search(name)
  }
  return response.slice(0, 5)
}

function getNewLimit(prevLimit, increaseNumber) {
  if (prevLimit === 100) {
    return increaseNumber
  }
  return prevLimit + increaseNumber
}

function mapModificationsAndCabinets(modifications, cabinets) {
  return modifications.map((modification) => {
    if (modification.resolved) return modification
    let suitableCabinets = cabinets.filter(
      (cabinet) =>
        cabinet.name.toLowerCase() === modification.name.toLowerCase()
    )
    if (suitableCabinets.length > 1) {
      return {
        needMoreInfo: true,
        caseId: SEVERAL_MATCHED,
        modification: {
          number: modification.number,
          name: modification.name,
          sourceCabinetName: modification.sourceCabinetName,
        },
        suitableCabinets,
        sourceLine: modification.sourceLine,
      }
    }
    if (suitableCabinets.length === 0) {
      suitableCabinets = getFuzzyMatchingCabinets(modification.name, cabinets)
      return {
        needMoreInfo: true,
        caseId: NONE_MATCHED,
        modification: {
          number: modification.number,
          name: modification.name,
          sourceCabinetName: modification.sourceCabinetName,
        },
        suitableCabinets,
        sourceLine: modification.sourceLine,
      }
    }
    const cabinet = suitableCabinets[0]
    return {
      cabinetName: cabinet.name,
      cabinetId: cabinet.id,
      previousLimit: cabinet.limit,
      newLimit: getNewLimit(cabinet.limit, modification.number),
      sourceLine: modification.sourceLine,
    }
  })
}

const defaultSetButtons = (buttons, cabinets) => {
  cabinets.forEach((cabinet, index) => {
    // eslint-disable-next-line no-param-reassign
    buttons[index] = {
      text: cabinet.name,
      value: cabinet,
    }
  })
}

async function handleEdgeCase(
  modifications,
  caseId,
  getMessage,
  setButtons = defaultSetButtons
) {
  const isEdgeCase = ({ needMoreInfo, caseId: modificationCaseId }) =>
    needMoreInfo && modificationCaseId === caseId
  const hasEdgeCases = Boolean(modifications.find(isEdgeCase))
  if (!hasEdgeCases) return modifications
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, modification] of modifications.entries()) {
    if (isEdgeCase(modification)) {
      const buttons = {
        abort: {
          text: 'Отмена',
          value: 'ABORT',
          className: 'swal-button-abort',
        },
        skip: {
          text: 'Пропустить',
          value: 'SKIP',
          className: 'swal-button-warning',
        },
      }
      setButtons(buttons, modification.suitableCabinets)
      // eslint-disable-next-line no-await-in-loop
      const response = await swal({
        icon: 'warning',
        text: getMessage(
          modification.modification.name,
          modification.modification.sourceCabinetName ||
            modification.modification.name,
          modification.suitableCabinets
        ),
        buttons,
        closeOnClickOutside: false,
        closeOnEsc: false,
      })
      if (response === 'SKIP') modification.skip = true
      else if (response === 'ABORT') return 'ABORT'
      else {
        // eslint-disable-next-line no-param-reassign
        modifications[index] = {
          cabinetName: response.name,
          cabinetId: response.id,
          previousLimit: response.limit,
          newLimit: getNewLimit(
            response.limit,
            modification.modification.number
          ),
          sourceLine: modification.sourceLine,
        }
      }
    }
  }
  return modifications
}

const getMessageForSeveralMatchedCase = (
  convertedCabinetName,
  sourceCabinetName
) => {
  if (convertedCabinetName === sourceCabinetName)
    return `Несколько кабинетов подошло под имя "${convertedCabinetName}"\nС каким из них будем работать?`
  return `
  Я конвертировал "${sourceCabinetName}"
  в "${convertedCabinetName}"
  и под это имя подошло несколько кабинетов
  С каким из них будем работать?`
}

function handleSeveralMatchedCase(modifications) {
  return handleEdgeCase(
    modifications,
    SEVERAL_MATCHED,
    getMessageForSeveralMatchedCase
  )
}

const getMessageForNoneMatchedCase = (
  convertedCabinetName,
  sourceCabinetName,
  suitableCabinets
) => {
  let start
  if (convertedCabinetName === sourceCabinetName)
    start = `Ни один кабинет не подошел под имя "${convertedCabinetName}"`
  else
    start = `
  Я конвертировал "${sourceCabinetName}"
  в "${convertedCabinetName}"
  и ни один кабинет не подошел под это имя`
  if (suitableCabinets.length === 0)
    return `${start}\nИ нет никаких похожих\nЧто будем делать?`
  if (suitableCabinets.length === 1)
    return `${start}\nНо есть один похожий:\n"${
      suitableCabinets[0].name
    }"\nБудем с ним работать?`
  return `${start}\nНо есть несколько похожих\nС каким из них будем работать?`
}

const setButtonsForNoneMatchedCase = (buttons, suitableCabinets) => {
  if (suitableCabinets.length === 1) {
    const cabinet = suitableCabinets[0]
    // eslint-disable-next-line no-param-reassign
    buttons[cabinet.id] = {
      text: 'Да!',
      value: cabinet,
    }
  } else defaultSetButtons(buttons, suitableCabinets)
}

function handleNoneMatchedCase(modifications) {
  return handleEdgeCase(
    modifications,
    NONE_MATCHED,
    getMessageForNoneMatchedCase,
    setButtonsForNoneMatchedCase
  )
}

async function resolveUnclearModifications(modifications, clientCabinets) {
  const localModifications = [...modifications]
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, modification] of localModifications.entries()) {
    if (modification.unclear) {
      const {
        number,
        cabinetId,
        abort,
        skip,
        // eslint-disable-next-line no-await-in-loop
      } = await SwalUtils.showSelectCabinetAndNumberSwal(
        modification.sourceLine,
        clientCabinets
      )
      if (abort) return 'ABORT'
      if (skip) {
        localModifications[index] = {
          resolved: true,
          skip: true,
          sourceLine: modification.sourceLine,
        }
      } else {
        const { name: cabinetName, limit } = clientCabinets.find(
          (cabinet) => cabinet.id === cabinetId
        )
        localModifications[index] = {
          resolved: true,
          cabinetName,
          cabinetId,
          previousLimit: limit,
          newLimit: getNewLimit(limit, number),
          sourceLine: modification.sourceLine,
        }
      }
    }
  }
  return localModifications
}

function throwIfDoubleChangeCabinet(modifications) {
  const cabinetNames = modifications
    .filter((modification) => !modification.skip)
    .map((modification) => modification.cabinetName)
  const duplicatedCabinetNames = getDuplicatedItems(cabinetNames)
  if (duplicatedCabinetNames.length) {
    let errorMessage
    if (duplicatedCabinetNames.length === 1) {
      errorMessage = `1 кабинет меняется дважды: ${duplicatedCabinetNames[0]}`
    } else {
      const duplicatedCabinetsString = duplicatedCabinetNames.join(', ')
      errorMessage = `Некоторые кабинеты меняются дважды: ${duplicatedCabinetsString}`
    }
    throw new Error(errorMessage)
  }
}

export {
  mapModificationsAndCabinets,
  getFuzzyMatchingCabinets,
  handleNoneMatchedCase,
  handleSeveralMatchedCase,
  handleEdgeCase,
  resolveUnclearModifications,
  getNewLimit,
  throwIfDoubleChangeCabinet,
}
