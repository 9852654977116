import './results-table.scss'
import '../static/fontello/css/fontello.css'
import { LIMITER } from './constants'
import { formatMoney } from './utils'
import { createHtmlLinkToCabinet } from './string-utils'
import { HistoryService } from './history-service'

function hasResultsWithError(modifications) {
  return Boolean(
    modifications.find((modification) => modification.success === false)
  )
}

function ensureHasErrorText(modifications) {
  return modifications.map((modification) => ({
    errorText: '',
    ...modification,
  }))
}

function addResult(modifications) {
  return modifications.map((modification) => {
    if (modification.success === true) {
      return {
        ...modification,
        result: '<i class="icon-success"></i> Успешно',
      }
    }
    if (modification.success === false) {
      return {
        ...modification,
        result: '<i class="icon-fail"></i> Ошибка',
      }
    }
    if (modification.skip) {
      return {
        ...modification,
        result: '<i class="icon-skip"></i> Пропущено',
      }
    }
    throw new Error(`invalid result object: ${JSON.stringify(modification)}`)
  })
}

function convertSlashNToBr(string) {
  return string.replace(/\n/g, '<br>')
}

function createLimiterHeader(needErrorColumn) {
  return `
    <th>Результат</th>
    <th>Вводная строка</th>
    <th>Кабинет</th>
    <th>Предыдущий лимит</th>
    <th>Новый лимит</th>
    ${needErrorColumn ? '<th>Ошибка (если была)</th>' : ''}
  `
}

function createGiveAccessHeader() {
  return `
    <th>Кабинет</th>
    <th>Результат</th>
    <th>Пользователь</th>
  `
}

function createLimiterTableRow(object, needErrorColumn) {
  return `
    <td class="result">${object.result}</td>
    <td>${object.sourceLine}</td>
    <td>${object.cabinetName ? createHtmlLinkToCabinet(object) : ''}</td>
    <td>${object.previousLimit ? formatMoney(object.previousLimit) : ''}</td>
    <td>${object.newLimit ? formatMoney(object.newLimit) : ''}</td>
    ${needErrorColumn ? `<td>${object.errorText || ''}</td>` : ''}
  `
}

function createUserCell(user) {
  return `
    <a
      href="https://vk.com/id${user.id}"
      class="vk-link"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img src="${user.photo_50}" alt="user avatar" class="user-avatar" />
      ${user.first_name} ${user.last_name}
    </a>
  `
}

function createGiveAccessTabRow(object) {
  return `
    <td>${createHtmlLinkToCabinet({
      cabinetName: object.cabinet.name,
      cabinetId: object.cabinet.id,
    })}</td>
    <td class="result">${object.result}</td>
    <td>${createUserCell(object)}</td>
  `
}

function createTable(needErrorColumn, modifications, mode) {
  return `
    <div class="table-responsive">
      <table id="results" class="table table-hover">
        <thead>
          <tr>
            ${
              mode === LIMITER
                ? createLimiterHeader(needErrorColumn)
                : createGiveAccessHeader()
            }
          </tr>
        </thead>
        <tbody>
          ${modifications
            .map(
              (object) => `
            <tr>
             ${
               mode === LIMITER
                 ? createLimiterTableRow(object, needErrorColumn)
                 : createGiveAccessTabRow(object)
             }
            </tr>
          `
            )
            .join('')}
        </tbody>
      </table>
    </div>
  `
}

function getTopText(topText) {
  return topText ? `<p>${convertSlashNToBr(topText)}</p>` : ''
}

function insertResults({
  needErrorColumn,
  modifications,
  mode,
  topText,
  loanNotification,
}) {
  const table = createTable(needErrorColumn, modifications, mode)
  document.querySelector('#app').innerHTML = `
    <div class="back-button-wrapper">
      <button id="back"><i class="icon-left-arrow"></i></button>
    </div>
    ${getTopText(topText)}
    ${table}
    ${
      loanNotification
        ? `<div class="notification">${loanNotification}</div>`
        : ''
    }
  `
  document.querySelector('#back').addEventListener('click', HistoryService.back)
}

function changeStageToResults() {
  document.body.classList.remove('input-stage')
  document.body.classList.add('results-stage')
}

function changeStageToInput() {
  document.body.classList.remove('results-stage')
  document.body.classList.add('input-stage')
}

function renderResults({
  results: passedResults,
  mode,
  needErrorColumn,
  topText,
  loanNotification,
}) {
  let results = addResult(passedResults)
  if (needErrorColumn) {
    results = ensureHasErrorText(results).map((result) => ({
      ...result,
      errorText: convertSlashNToBr(result.errorText),
    }))
  }
  changeStageToResults()
  insertResults({
    needErrorColumn,
    modifications: results,
    mode,
    topText,
    loanNotification,
  })
}

export {
  renderResults,
  hasResultsWithError,
  convertSlashNToBr,
  ensureHasErrorText,
  addResult,
  createTable,
  getTopText,
  changeStageToInput,
}
