/* eslint-disable import/no-cycle */
import { vk } from './vk-utils'
import { decrypt } from './decrypt-service'

export class VkStorageService {
  static async getByKeys(keys) {
    return VkStorageService.request('storage.get', {
      keys: keys.join(','),
    })
  }

  static async getByKey(key) {
    const response = await VkStorageService.request('storage.get', {
      keys: key,
    })
    return response[0].value
  }

  static async set(key, value) {
    return VkStorageService.request('storage.set', { key, value })
  }

  /**
   * @private
   * @param {string} method
   * @param {Object} data
   * @returns {Promise<any>}
   */
  static async request(method, data) {
    const {
      serviceToken,
      settingsUserId,
    } = await VkStorageService.getServiceTokenAndSettingsUserId()
    return vk({
      method,
      data: {
        access_token: serviceToken,
        user_id: settingsUserId,
        ...data,
      },
      hardRetryIfNetworkError: true,
    })
  }

  /**
   * @private
   * @returns {Promise<{settingsUserId: string, serviceToken: string}>}
   */
  static async getServiceTokenAndSettingsUserId() {
    let serviceToken
    if (process.env.NODE_ENV === 'production') {
      const encryptedServiceToken = process.env.ENCRYPTED_PROD_VK_SERVICE_TOKEN
      serviceToken = await decrypt(encryptedServiceToken)
    } else serviceToken = process.env.DEV_VK_SERVICE_TOKEN
    let settingsUserId
    if (process.env.NODE_ENV === 'production') {
      const encryptedSettingsUserId =
        process.env.ENCRYPTED_PROD_SETTINGS_USER_ID
      settingsUserId = await decrypt(encryptedSettingsUserId)
    } else settingsUserId = process.env.DEV_SETTINGS_USER_ID
    return {
      serviceToken,
      settingsUserId,
    }
  }
}
