import * as Sentry from '@sentry/browser'
import swal from 'sweetalert'

function isProduction() {
  return process.env.NODE_ENV === 'production'
}

function initErrorHandler() {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    beforeSend(event, hint) {
      if (
        event.exception &&
        !(
          hint &&
          hint.originalException &&
          hint.originalException.haveShownError
        )
      ) {
        const additionalInfo =
          hint && hint.originalException && hint.originalException.message
            ? `Вот дополнительная информация:
            ${hint.originalException.message}`
            : ''
        swal({
          icon: 'error',
          title: 'Возникла ошибка',
          text: `Разработчик уже уведомлен\n${additionalInfo}`,
        })
      }
      if (!isProduction()) return null
      return event
    },
  })
}

export { initErrorHandler }
