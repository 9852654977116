import { showLoader } from '../common/loader'
import { getClientCabinets, updateLimits } from '../common/vk-utils'
import { parseData, tryToGuessFormat } from './data-parser'
import {
  handleNoneMatchedCase,
  handleSeveralMatchedCase,
  mapModificationsAndCabinets,
  resolveUnclearModifications,
  throwIfDoubleChangeCabinet,
} from './map-modifications'
import {
  changeStageToInput,
  hasResultsWithError,
  renderResults,
} from '../common/render-results'
import { addReserveModification, getReserveCabinetIdByFormat } from './reserve'
import {
  CHANGE_SOURCES,
  DISABLE_RESERVE_FORMAT_ID,
  LIMITER,
} from '../common/constants'
import { sendLog } from '../common/log-utils'
import { tryToSetReserveCabinet } from '../common/user-utils'
import throttle from 'lodash.throttle'
import { HistoryService } from '../common/history-service'

let userManuallySetFormat = false

async function main() {
  const elementIdsToDisable = [
    'decrease-reserve',
    'data-input-area',
    'format-1',
    'format-2',
    'format-3',
    'format-4',
    'format-5',
  ]
  const hideLoader = showLoader({ elementIdsToDisable })
  let modifications = []
  let loanNotification = ''
  try {
    const decreaseReserveCheckbox = document.querySelector('#decrease-reserve')
    const format = +document.querySelector('input[name="format"]:checked').value
    const shouldDecreaseReserve = decreaseReserveCheckbox.checked
    const inputValue = document.querySelector('#data-input-area').value
    const clientCabinets = await getClientCabinets()
    modifications = parseData(inputValue, format)
    modifications = await resolveUnclearModifications(
      modifications,
      clientCabinets
    )
    if (modifications === 'ABORT') return hideLoader()
    modifications = mapModificationsAndCabinets(modifications, clientCabinets)
    modifications = await handleSeveralMatchedCase(modifications)
    if (modifications === 'ABORT') return hideLoader()
    modifications = await handleNoneMatchedCase(modifications)
    if (modifications === 'ABORT') return hideLoader()
    if (shouldDecreaseReserve && format !== DISABLE_RESERVE_FORMAT_ID) {
      let reserveCabinetId = await getReserveCabinetIdByFormat(format)
      if (!reserveCabinetId) {
        reserveCabinetId = await tryToSetReserveCabinet(clientCabinets, format)
      }
      ;({ modifications, loanNotification } = await addReserveModification({
        modifications,
        clientCabinets,
        reserveCabinetId,
      }))
    }
    throwIfDoubleChangeCabinet(modifications)
    modifications = await updateLimits(modifications)
    HistoryService.setBackHandler(() =>
      // eslint-disable-next-line no-use-before-define
      restoreInitialContent(inputValue, shouldDecreaseReserve, format)
    )
    HistoryService.pushState()
    renderResults({
      results: modifications,
      mode: LIMITER,
      needErrorColumn: hasResultsWithError(modifications),
      loanNotification,
    })
  } catch (e) {
    changeStageToInput()
    hideLoader()
    throw e
  }
  await sendLog(modifications, loanNotification)
}

function renderInitialContent(areaValue, shouldDecreaseReserve, format) {
  const getChecked = (inputFormatId) =>
    format === inputFormatId ? 'checked' : ''
  document.querySelector('#app').innerHTML = `
    <div class="form-group input-area-wrapper">
      <label for="data-input-area">Данные модификаций</label>
      <textarea id="data-input-area" class="form-control input-area" cols="70" rows="20">${areaValue}</textarea>
    </div>
    <div class="bottom">
      <label>
        <input name="format" type="radio" value="1" id="format-1" ${getChecked(
          1
        )}> Форт Боярд
      </label>
      <label>
        <input name="format" type="radio" value="2" id="format-2" ${getChecked(
          2
        )}> Emitsu
      </label>
      <label>
        <input name="format" type="radio" value="3" id="format-3" ${getChecked(
          3
        )}> Рыжая / резерв
      </label>
      <label>
        <input name="format" type="radio" value="4" id="format-4" ${getChecked(
          4
        )}> Рыжая / оффшор
      </label>
      <label>
        <input name="format" type="radio" value="5" id="format-5" ${getChecked(
          5
        )}> Кривенко
      </label>
      <label for="decrease-reserve" class="decrease-reserve-label">
        <input type="checkbox" id="decrease-reserve" ${
          shouldDecreaseReserve ? 'checked' : ''
        }>
        Уменьшать резерв
      </label>
      <button class="main-button" id="start-button">Старт</button>
    </div>
  `
  const formatRadioButtons = document.querySelectorAll('input[name="format"]')
  const decreaseReserveCheckbox = document.querySelector('#decrease-reserve')

  function onFormatChange(changeSource) {
    if (changeSource === CHANGE_SOURCES.USER) userManuallySetFormat = true
    const disableReserveFormatIsSelected = Boolean(
      document.querySelector(
        `input[name="format"][value="${DISABLE_RESERVE_FORMAT_ID}"]:checked`
      )
    )
    if (disableReserveFormatIsSelected) {
      decreaseReserveCheckbox.checked = false
      decreaseReserveCheckbox.setAttribute('disabled', 'disabled')
    } else {
      decreaseReserveCheckbox.removeAttribute('disabled')
    }
  }

  ;[...formatRadioButtons].forEach((button) => {
    button.addEventListener('change', () => onFormatChange(CHANGE_SOURCES.USER))
  })
  const startButton = document.querySelector('#start-button')
  startButton.addEventListener('click', main)
  const inputArea = document.querySelector('#data-input-area')

  function guessFormat(inputData) {
    if (userManuallySetFormat) return
    const guessedFormat = tryToGuessFormat(inputData)
    if (guessedFormat) {
      document.querySelector(
        `input[name="format"][value="${guessedFormat}"]`
      ).checked = true
      onFormatChange(CHANGE_SOURCES.PROGRAM)
    }
  }

  function onInputAreaKeyup(e) {
    const inputData = e.target.value
    guessFormat(inputData)
  }

  const throttledKeyup = throttle(onInputAreaKeyup, 500, { trailing: true })
  inputArea.addEventListener('keyup', throttledKeyup)
  inputArea.addEventListener('paste', (e) => {
    const inputData = e.clipboardData.getData('text')
    guessFormat(inputData)
  })
}

function restoreInitialContent(inputValue, shouldDecreaseReserve, format) {
  changeStageToInput()
  renderInitialContent(inputValue, shouldDecreaseReserve, format)
}

export { renderInitialContent, restoreInitialContent }
