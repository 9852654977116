import './index-styles.css'
import '../common/bootstrap-forms.scss'
import { renderInitialContent } from './render-initial-content'
import { initPage } from '../common/init-page'

const init = async () => {
  await initPage()
  renderInitialContent('', false, 1)
}
init()
