import { getReserveCabinetsByIndexes } from '../change-reserve-page/reserve-cabinets-service'
import { LoanService } from './loan-service'
import { MIN_VK_CABINET_LIMIT } from '../common/constants'

function getDecreaseNumber(prevLimit, newLimit) {
  if (prevLimit === 100) return newLimit
  return newLimit - prevLimit
}

async function addReserveModification({
  modifications,
  clientCabinets,
  reserveCabinetId,
}) {
  let decreaseNumber = 0
  modifications
    .filter(({ skip }) => !skip)
    .forEach(({ previousLimit, newLimit }) => {
      decreaseNumber += getDecreaseNumber(previousLimit, newLimit)
    })
  const reserveCabinet = clientCabinets.find(
    (cabinet) => cabinet.id === reserveCabinetId
  )
  if (!reserveCabinet)
    throw new Error(
      `не найден резервный кабинет среди кабинетов. id: "${reserveCabinetId}"`
    )
  let newLimit = reserveCabinet.limit - decreaseNumber
  let changeLoanNumber = 0
  if (newLimit < MIN_VK_CABINET_LIMIT) {
    changeLoanNumber = MIN_VK_CABINET_LIMIT - newLimit
  }
  newLimit += changeLoanNumber
  const loanNotification = LoanService.getLoanNotification({
    increaseNumber: changeLoanNumber,
  })
  modifications.push({
    cabinetName: reserveCabinet.name,
    cabinetId: reserveCabinet.id,
    previousLimit: reserveCabinet.limit,
    newLimit,
    sourceLine: `[резерв] –${decreaseNumber}`,
  })
  return { modifications, loanNotification }
}

async function getReserveCabinetIdByFormat(formatId) {
  const response = await getReserveCabinetsByIndexes([formatId])
  return response[0].id
}

export { addReserveModification, getReserveCabinetIdByFormat }
