import escape from 'regexp.escape'

function createLinkToCabinet(cabinetId) {
  return `https://vk.com/ads?act=office&union_id=${cabinetId}`
}

function createHtmlLinkToCabinet({ cabinetName, cabinetId }) {
  return `<a
      target="blank"
      class="vk-link"
      rel="noreferrer noopener"
      href="${createLinkToCabinet(cabinetId)}">${cabinetName}</a>`.replace(
    /\n/g,
    ''
  )
}

function getNumbers(string) {
  return string.replace(/\D/g, '')
}

function ensureHasPrefix(string, prefix) {
  if (!string || !prefix) return string
  const regex = new RegExp(`^${prefix} `, 'i')
  const stringWithoutPrefix = string.replace(regex, '')
  return `${prefix} ${stringWithoutPrefix}`
}

function removeByRegex(
  string,
  stringsToRemove,
  createRegex = (regex) => regex
) {
  let processedString = string
  stringsToRemove.forEach((word) => {
    processedString = processedString.replace(
      new RegExp(createRegex(escape(word)), 'iug'),
      ''
    )
  })
  return processedString.trim()
}

function removeWords(string, wordsToRemove) {
  return removeByRegex(
    string,
    wordsToRemove,
    (regex) => `([\\s,.:;"']|^)${regex}(?=[\\s,.:;"']|$)`
    // see
    // https://medium.com/@shiba1014/regex-word-boundaries-with-unicode-207794f6e7ed
    // Cannot use lookbehind because Safari doesn't support it
    // https://stackoverflow.com/a/51568859/7119080
    // https://caniuse.com/js-regexp-lookbehind
  )
}

function removeStrings(string, stringsToRemove) {
  return removeByRegex(string, stringsToRemove)
}

export {
  createHtmlLinkToCabinet,
  createLinkToCabinet,
  getNumbers,
  ensureHasPrefix,
  removeStrings,
  removeWords,
  removeByRegex,
}
