import swal from 'sweetalert'
// eslint-disable-next-line import/no-cycle
import { vk } from './vk-utils'
import '../index-page/swal-with-select2.css'
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'
import { API_VERSION, SELECT_OPTIONS } from './constants'
import { waitForTimeout } from './utils'
// eslint-disable-next-line import/no-cycle
import { setReserveCabinets } from '../change-reserve-page/reserve-cabinets-service'

async function authenticate(
  mainMessage = 'Добро пожаловать!\nДля начала нужно авторизоваться ВКонтакте',
  buttonText = 'Авторизоваться ВК',
  doubleTry = true,
  showCancel = false
) {
  const appId = 7282612
  const redirectUri = window.location.href
  const authUrl = `https://oauth.vk.com/authorize?client_id=${appId}&redirect_uri=${redirectUri}&display=page&scope=ads,offline&response_type=token&v=${API_VERSION}`
  const buttons = {
    cancel: showCancel && {
      visible: true,
      value: null,
      text: 'Отмена',
    },
    proceed: {
      value: true,
      text: buttonText,
    },
  }
  let response = await swal({
    text: mainMessage,
    buttons,
  })
  const redirect = async () => {
    window.location = authUrl
    // redirect takes some time
    // need to wait for it
    // another way we suppose that user is authenticated
    // and try to use undefined access token
    await waitForTimeout(300)
  }
  if (response) await redirect()
  else if (doubleTry) {
    response = await swal({
      icon: 'warning',
      text: 'К сожалению, без авторизации скрипт не сможет работать',
      buttons,
    })
    if (response) await redirect()
  }
}

async function tryToSetReserveCabinet(clientCabinets, format) {
  if (!clientCabinets.length) {
    return swal({
      icon: 'error',
      text:
        'Нет клиентов в выбранном агентском кабинете :(\n' +
        'Выбери другой кабинет',
    })
  }
  const swalContent = `
    <p>Выбери резервный кабинет:</p>
    <select id="reserve-cabinet-select">
      ${clientCabinets
        .map(
          (cabinet) => `<option value="${cabinet.id}">${cabinet.name}</option>`
        )
        .join('')}
    </select>
  `
  const wrapper = document.createElement('div')
  wrapper.innerHTML = swalContent
  const promise = swal({
    content: wrapper,
    buttons: {
      confirm: {
        value: true,
        text: 'Выбрать',
      },
    },
    closeOnEsc: false,
    closeOnClickOutside: false,
  })

  const select = $('#reserve-cabinet-select')
  select.select2({
    ...SELECT_OPTIONS,
    placeholder: 'Выбрать',
  })
  let selectedCabinetId = clientCabinets[0].id
  select.on('select2:select', (e) => {
    selectedCabinetId = +e.target.value
  })
  await promise
  await setReserveCabinets([{ value: selectedCabinetId, index: format }])
  return selectedCabinetId
}

async function tryToSetCabinetId(allowEscape = false) {
  const cabinets = await vk({
    method: 'ads.getAccounts',
  })
  const agencyCabinets = cabinets
    .filter((cabinet) => cabinet.account_type === 'agency')
    .filter((cabinet) => cabinet.account_status === 1)
    .filter((cabinet) => ['admin', 'manager'].includes(cabinet.access_role))
    .map((cabinet) => ({
      id: cabinet.account_id,
      name: cabinet.account_name,
    }))
  const buttons = {
    cancel: allowEscape && {
      text: 'Отмена',
      visible: true,
      value: null,
    },
  }
  agencyCabinets.forEach(({ id, name }) => {
    buttons[id] = {
      text: name,
      value: id,
    }
  })
  const cabinetId = await swal({
    text: 'Выбери агентский кабинет,\nс которым будешь работать:',
    buttons,
    closeOnClickOutside: allowEscape,
    closeOnEsc: allowEscape,
  })
  if (cabinetId) {
    localStorage.setItem('cabinetId', cabinetId)
    await swal({ icon: 'success', text: 'Сохранено' })
  }
  return cabinetId
}

async function tryToSetAccessTokenFromUrl() {
  const { location } = window
  const { hash } = location
  if (!hash) return
  const vkTokenHashRegex = /^#access_token=(\S+)&expires_in=0&user_id=\d*$/
  const match = hash.match(vkTokenHashRegex)
  if (!match) return
  const accessToken = match[1]
  localStorage.setItem('accessToken', accessToken)
  await swal({
    icon: 'success',
    text: 'Ты успешно авторизовался! Теперь можно пользоваться сервисом',
  })
  window.history.replaceState(
    {},
    '',
    location.href.slice(0, location.href.indexOf('#'))
  )
}

async function initUserState() {
  await tryToSetAccessTokenFromUrl()
  if (localStorage.getItem('accessToken') === null) {
    return authenticate()
  }
  if (localStorage.getItem('cabinetId') === null) {
    await tryToSetCabinetId()
  }
}

export {
  initUserState,
  authenticate,
  tryToSetCabinetId,
  tryToSetReserveCabinet,
}
