import './loader.scss'

function disableById(elementId) {
  const element = document.getElementById(elementId)
  element.setAttribute('disabled', 'disabled')
}

function enableById(elementId) {
  const element = document.getElementById(elementId)
  element.removeAttribute('disabled')
}

function hideLoader({ elementIdsToEnable, buttonId, buttonText }) {
  const button = document.getElementById(buttonId)
  button.innerText = buttonText
  enableById(buttonId)
  elementIdsToEnable.forEach((elementId) => enableById(elementId))
}

function showLoader({ elementIdsToDisable, buttonId = 'start-button' }) {
  const button = document.getElementById(buttonId)
  const buttonText = button.innerText
  button.innerHTML = `
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">Обработка...</span>
  `
  disableById(buttonId)
  elementIdsToDisable.forEach((elementId) => disableById(elementId))
  return () =>
    hideLoader({
      elementIdsToEnable: elementIdsToDisable,
      buttonId,
      buttonText,
    })
}

export { showLoader, hideLoader }
