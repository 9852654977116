import {
  getNumberFromLine,
  getTextareaLines,
  isNumberLine,
  parseMoney,
  removeCabinetRelatedWords,
  removeSpaces,
} from '../common/utils'
import {
  ensureHasPrefix,
  removeStrings,
  removeWords,
} from '../common/string-utils'
import { FORT_BOYARD_PREFIX, RYZHAYA_PREFIX } from '../common/constants'

function isStringNumber(str) {
  return RegExp(/^\d+$/).test(str)
}

function tryToGetNumberFromFirstLine(line) {
  const redundantStrings = [
    'перенесите',
    'пожалуйста',
    'перекинуть',
    'перекиньте',
    'можно',
    'попросить',
    'полетело',
    'из',
    ',',
  ]
  let string = removeStrings(line, redundantStrings)
  string = removeCabinetRelatedWords(string)
  if (!isNumberLine(string)) throw new Error('invalid string')
  return parseMoney(string)
}

function convertNameForFirstFormat(name) {
  const citiesMap = {
    СПб: 'Санкт-Петербург',
    Ростов: 'Ростов-на-Дону',
  }
  let workingName = name
  workingName = name.replace(/фб/i, '').trim()
  workingName = workingName.replace('  2.0', ' 2.0')
  Object.entries(citiesMap).forEach(([cityShortcut, fullCityName]) => {
    if (!workingName.includes(fullCityName))
      workingName = workingName.replace(cityShortcut, fullCityName)
  })
  return ensureHasPrefix(workingName, FORT_BOYARD_PREFIX)
}

function parseSecondFormat(
  lines,
  prefix = '',
  linePreprocessor = (line) => line
) {
  // detailed regex tests: https://regexr.com/4utbt
  const regex = /^[А-Яа-яA-Za-z/_ ]+ (\d+ ?к|\d+( \d+ ?к)?)$/
  return lines
    .map((line) => {
      const processedLine = linePreprocessor(line)
      if (!processedLine.match(/\d/)) return false
      const match = processedLine.match(regex)
      if (!match)
        return {
          unclear: true,
          sourceLine: line,
        }
      const numberPart = match[2] || match[1]
      const number = parseMoney(numberPart)
      const name = processedLine
        .substring(0, processedLine.indexOf(numberPart))
        .trim()
      return {
        number,
        name: ensureHasPrefix(name, prefix),
        sourceLine: line,
      }
    })
    .filter(Boolean)
}

function preprocessSecondFormatLine(line) {
  const redundantWords = [
    'пожалуйста',
    'плиз',
    '@adwingsru',
    '@ilya_og',
    'кабинете',
    'кабинет',
    'кабе',
    'каб',
    'на',
    'в',
    'р',
  ]
  const redundantStrings = [',', '+']
  const result = removeWords(line, redundantWords)
  return removeStrings(result, redundantStrings)
}

const parsersMap = {
  1: (lines) => {
    return lines
      .map((line) => {
        const firstWordCharIndex = line.search(/[0-9]*[A-Za-zА-Яа-я]+/)
        if (firstWordCharIndex === -1) return false
        let number = line.substring(0, firstWordCharIndex)
        const name = line.substring(firstWordCharIndex).trim()
        number = removeSpaces(number)
        if (!isStringNumber(number)) return false
        number = +number
        return {
          number,
          name: convertNameForFirstFormat(name),
          sourceCabinetName: name,
          sourceLine: line,
        }
      })
      .filter(Boolean)
  },
  2: (lines) => parseSecondFormat(lines, '', preprocessSecondFormatLine),
  3: (lines) =>
    parseSecondFormat(lines, RYZHAYA_PREFIX, preprocessSecondFormatLine),
  4: (lines) =>
    parseSecondFormat(lines, RYZHAYA_PREFIX, preprocessSecondFormatLine),
  5: (lines) => {
    const createModification = (name, number) => ({
      name,
      number,
      sourceLine: `${name} ${number}`,
    })

    const cabinetLines = lines.filter((line) => line.includes(' / '))
    const numberLines = lines.filter((line) => isNumberLine(line))
    if (cabinetLines.length === 0)
      throw new Error(
        'Я не смог найти строки с названиями кабинетов. ' +
          'Каждый кабинет должен быть на отдельной строке ' +
          'и должен включать в себя знак "/"'
      )
    const transferKeywords = [
      'перенести',
      'перенесите',
      'перекинуть',
      'перекиньте',
      'перевести',
      'переведите',
    ]
    const messageStartsWithTransfer = transferKeywords.some((start) =>
      lines[0].toLowerCase().includes(start)
    )

    if (
      messageStartsWithTransfer &&
      numberLines.length === 0 &&
      cabinetLines.length === 2
    ) {
      let number
      try {
        number = tryToGetNumberFromFirstLine(lines[0])
      } catch (e) {
        throw new Error(
          'Похоже, что ты хочешь перенести деньги из одного кабинета в один другой\n' +
            'Но я не смог найти сумму переноса ни в первой строке, ни на других строках'
        )
      }
      return [
        createModification(cabinetLines[0], -number),
        createModification(cabinetLines[1], number),
      ]
    }

    if (numberLines.length === 0)
      throw new Error(
        'Я не смог найти строки с числами, на сколько пополнять кабинеты. ' +
          'Каждое число должно быть на отдельной строке'
      )
    const cabinetLineBeforeNumberLine =
      lines.indexOf(cabinetLines[0]) < lines.indexOf(numberLines[0])
    if (messageStartsWithTransfer) {
      if (!cabinetLineBeforeNumberLine)
        throw new Error(
          'Похоже ты хочешь перенести деньги из одного кабинета в 1 ' +
            'или несколько других. В таком случае строка с названием кабинета, ' +
            'баланс которого нужно увеличить, должна идти раньше суммы увеличения'
        )
      const minusCabinet = cabinetLines[0]
      const plusCabinets = cabinetLines.slice(1)
      if (numberLines.length !== plusCabinets.length) {
        throw new Error(`Похоже, ты хочешь перенести деньги из одного кабинета
        в 1 или несколько других. В таком случае кол-во строк
        с названием кабинетов должно быть на 1 больше чем
        кол-во строк с числами.
        Сейчас же найдено строк с числами: ${numberLines.length}
        И строк с названиями кабинетов: ${cabinetLines.length}
        `)
      }
      let decreaseNumber = 0
      const numbers = numberLines.map((line) => getNumberFromLine(line))
      numbers.forEach((number) => {
        decreaseNumber += number
      })
      const minusModification = createModification(
        minusCabinet,
        -decreaseNumber
      )
      const plusModifications = plusCabinets.map((cabinetName, index) => {
        const cabinetNumber = numbers[index]
        return createModification(cabinetName, cabinetNumber)
      })
      return [minusModification, ...plusModifications]
    }
    if (!cabinetLineBeforeNumberLine) {
      if (cabinetLines.length !== numberLines.length)
        throw new Error(
          `Похоже, ты хочешь просто пополнить кабинеты. Без переноса денег.
          В таком случае кол-во строк с названиями кабинетов должно быть равно
          кол-ву строк с суммами увеличения.
          Сейчас же найдено строк с числами: ${numberLines.length}
          И строк с названиями кабинетов: ${cabinetLines.length}
        `
        )
      return cabinetLines.map((cabinetName, index) => {
        const cabinetNumber = getNumberFromLine(numberLines[index])
        return createModification(cabinetName, cabinetNumber)
      })
    }
    throw new Error(
      'Незнаковая вариация 3его формата. Используй вариации из этой доки:' +
        'https://docs.google.com/document/d/1qawDC2oj3RJENUErgninrIa5ysbbIXUc5RYDkoHkxMg/edit?usp=sharing'
    )
  },
}

function parseData(data, format) {
  const lines = getTextareaLines(data)
  return parsersMap[format](lines)
}

function tryToGuessFormat(data) {
  let guessedFormat = 0
  Object.keys(parsersMap).forEach((formatId) => {
    try {
      const modifications = parseData(data, formatId).filter(
        (modification) =>
          !modification.unclear && !Number.isNaN(modification.number)
      )
      if (modifications.length) {
        guessedFormat = formatId
      }
    } catch (e) {} // eslint-disable-line no-empty
  })
  return +guessedFormat
}

export {
  parseData,
  isStringNumber,
  convertNameForFirstFormat,
  tryToGuessFormat,
}
