function splitToChunks(array, number) {
  const newArray = []
  for (let i = 0; i < array.length; i += number)
    newArray.push(array.slice(i, i + number))
  return newArray
}

function flatten(arr, result = []) {
  for (let i = 0, length = arr.length; i < length; i += 1) {
    const value = arr[i]
    if (Array.isArray(value)) {
      flatten(value, result)
    } else {
      result.push(value)
    }
  }
  return result
}

function getTextareaLines(data) {
  return data
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => Boolean(line))
}

function waitForTimeout(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

function removeSpaces(string) {
  return string.replace(/\s/g, '')
}

function parseMoney(stringNumber) {
  return +removeSpaces(stringNumber).replace(/к$/, '000')
}

/**
 * Split number by groups
 * @param {Number} number
 * @returns {string}
 */
function formatMoney(number) {
  const reversedCharsArray = String(number)
    .split('')
    .reverse()
  const result = []
  reversedCharsArray.forEach((digit, index) => {
    if (index % 3 === 0 && index !== 0) result.push(' ')
    result.push(digit)
  })
  return result.reverse().join('')
}

function removeCabinetRelatedWords(string) {
  return string
    .replace(/^и /i, '')
    .replace(/ с /, '')
    .replace(/на/i, '')
    .replace(/кабинета?/, '')
    .replace(/каба?/, '')
    .trim()
}

function isNumberLine(line) {
  let string = removeCabinetRelatedWords(line)
  string = removeSpaces(string)
  const numbersCount = string.replace(/\D/g, '').length
  const notNumbersCount = string.replace(/\d/g, '').length
  return numbersCount / notNumbersCount >= 1
}

function getNumberFromLine(line) {
  const stringNumber = removeCabinetRelatedWords(line)
  return parseMoney(stringNumber)
}

function getDuplicatedItems(array) {
  const seen = new Set()
  const duplicates = array.filter((item) => {
    if (seen.has(item)) {
      return true
    }
    seen.add(item)
    return false
  })
  return [...new Set(duplicates)]
}

async function handleChunkedMethod({ objects, requestFn, limit = 50 }) {
  const chunks = splitToChunks(objects, limit)
  const promises = chunks.map((chunk) => {
    const requestFnResult = requestFn(chunk)
    if (!requestFnResult || typeof requestFnResult.then !== 'function')
      throw new Error(
        `requestFn should return promise, but it returned ${requestFnResult}`
      )
    return requestFnResult
  })
  const results = await Promise.all(promises)
  results.forEach((result, index) => {
    if (!Array.isArray(result))
      throw new Error(
        `invalid result from requestFn promise #${index}: "${result}"`
      )
  })
  return flatten(results)
}

export {
  splitToChunks,
  flatten,
  getTextareaLines,
  waitForTimeout,
  parseMoney,
  removeSpaces,
  isNumberLine,
  removeCabinetRelatedWords,
  getNumberFromLine,
  getDuplicatedItems,
  formatMoney,
  handleChunkedMethod,
}
